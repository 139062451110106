
import React from "react"
import Layout from "../../components/Layout"
import ProtectedPage from '../../components/common/ProtectedPage'
import NamePage from "../../components/Account/Name"


const ChangeNamePage = () => (
  <Layout seoProps={{title: "Change Your Name"}}>
    <ProtectedPage>
        <NamePage />
    </ProtectedPage>
  </Layout>
)

export default ChangeNamePage
